type pageTypes =
  | "joinRequest"
  | "listing"
  | "market"
  | "opportunity"
  | "organization"
  | "property"
  | "request"
  | "submissionQueue"
  | "user";

const pageTypeMap: Record<pageTypes, keyof typeof fusionRoutes> = {
  joinRequest: "joinRequests",
  listing: "listings",
  market: "markets",
  opportunity: "opportunities",
  organization: "organizations",
  property: "properties",
  request: "joinRequests",
  submissionQueue: "submissionQueue",
  user: "users",
};

export const getFusionDetailPath = (
  type: pageTypes,
  detailId: string,
  secondary = "detail",
) => {
  // @ts-expect-error hootie hoo
  return fusionRoutes[pageTypeMap[type]][secondary].replace(
    `:${type}Id`,
    detailId,
  );
};

export const fusionRoutes = {
  dashboard: "/dashboard",
  joinRequests: {
    detail: "/users/join-requests/:requestId",
    list: "/users/join-requests",
  },
  listings: {
    list: "/opportunities/listings",
    detail: "/opportunities/detail/:listingId",
  },
  markets: {
    addNew: "/markets/add",
    detail: "/markets/:marketId",
    list: "/markets",
    map: "/markets/:marketId/map",
    organizations: "/markets/:marketId/organizations",
    properties: "/markets/:marketId/properties",
  },
  opportunities: {
    deals: "https://kaiizen.pipedrive.com/pipeline/5/user/everyone",
    inquiries: "/opportunities/inquiries",
    list: "/opportunities/inquiries",
    listings: "/opportunities/listings/",
  },
  organizations: {
    addNew: "/organizations/add",
    buyboxes: "/organizations/:organizationId/buyboxes",
    detail: "/organizations/:organizationId",
    list: "/organizations",
    users: "/organizations/:organizationId/users",
  },
  properties: {
    detail: "/properties/:propertyId",
    list: "/properties",
    photos: "/properties/:propertyId/photos",
  },
  submissionQueue: {
    detail: "/opportunities/submission-queue/:submissionQueueId",
    list: "/opportunities/submission-queue",
  },
  users: {
    addNew: "/users/add",
    detail: "/users/:userId",
    list: "/users",
  },
};
