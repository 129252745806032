import { useState } from "react";

import { PropertySnapshot, PropertyDetailPhoto } from "~/dataServices";

import { detailArrayAsString, detailIsIncluded } from "~/clients/zen/lib";
import {
  formatRenoArray,
  PropertyAccordion,
  accordionPhotoState,
  DetailScreen,
  filterDetailPhotos,
  PhotoNavigator,
} from "~/clients/zen/partials";
import { getFusionDetailPath } from "~/clients/fusion/fusionRoutes";

const toAcres = (sqFt?: number) => {
  if (!sqFt) return 0;

  return (sqFt / 43560).toFixed(2);
};

export const PropertyDetail = ({
  property,
  photos,
  renoTotal,
}: {
  property: PropertySnapshot;
  photos: PropertyDetailPhoto[];
  renoTotal: number[];
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  const { state: propVals } = property;
  const filteredPhotos = filterDetailPhotos(photos, "property");
  const getPropertyPhotoState = accordionPhotoState(
    filteredPhotos,
    setPhotoIndex,
  );

  return (
    <DetailScreen className="container">
      <h4>Property</h4>
      <div className="wrap">
        <div className="left">
          <PropertyAccordion
            title="Parcel"
            titleValue={`${toAcres(property.lotSqft)} Acres`}
            photoState={getPropertyPhotoState("property/lot")}
            rows={[
              {
                name: "Lot",
                value: toAcres(property.lotSqft),
              },
              {
                name: "Estimated Reno Cost",
                value: formatRenoArray(renoTotal),
              },
            ]}
          />
          {detailIsIncluded("fencing", propVals) && (
            <PropertyAccordion
              title="Fencing"
              titleValue={detailArrayAsString(propVals.fencingType)}
              photoState={getPropertyPhotoState("property/fencing")}
              rows={[
                {
                  name: "Type",
                  value: detailArrayAsString(propVals.fencingType),
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.fencingRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("driveway", propVals) && (
            <PropertyAccordion
              title="Driveway"
              titleValue={detailArrayAsString(propVals.drivewayType)}
              photoState={getPropertyPhotoState("property/driveway")}
              rows={[
                {
                  name: "Type",
                  value: detailArrayAsString(propVals.drivewayType),
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.drivewayRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("pool", propVals) && (
            <PropertyAccordion
              title="Pool"
              titleValue={detailArrayAsString(propVals.poolType)}
              photoState={getPropertyPhotoState("property/pool")}
              rows={[
                { name: "Type", value: detailArrayAsString(propVals.poolType) },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(propVals.poolRenoEstimateRangeDollars),
                },
              ]}
            />
          )}
          {detailIsIncluded("additionalStructures", propVals) && (
            <PropertyAccordion
              title="Additional Structures"
              photoState={getPropertyPhotoState(
                "property/additional-structures",
              )}
              rows={[
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.additionalStructuresRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          <PropertyAccordion
            title="Total Property Reno"
            titleValue={formatRenoArray(renoTotal)}
            rows={
              [
                // { name: "Total Estimated Timeline", value: "xxx days" },
              ]
            }
          />
        </div>
        <div className={`right ${filteredPhotos.length > 0 && "photo-sizer"}`}>
          <PhotoNavigator
            photos={photos}
            currentIndex={photoIndex}
            onPhotoChangeClick={setPhotoIndex}
            photoBackgroundSize={"contain"}
            paginationAlign={"right"}
            filteredPhotos={filteredPhotos}
            category={"property"}
            onSeeAllClick={() => {
              window.location.assign(
                getFusionDetailPath("property", property.propertyId, "photos"),
              );
            }}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
