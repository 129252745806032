import { useState } from "react";
import styled from "@emotion/styled";

import { PropertySnapshot, PhotoCategoryType } from "~/dataServices";

import { PropertyDetailPhoto } from "~/dataServices";
import { breakpointS, colors } from "~/clients/zen/styles";
import {
  PropertyAccordion,
  PropertyMap,
  PhotoNavigator,
} from "~/clients/zen/partials";
import { getFusionDetailPath } from "~/clients/fusion/fusionRoutes";

export const DetailScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 20px;

  > h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding: 25px 0;

    &:after {
      background-color: ${colors["gray-divider"]};
      content: " ";
      display: block;
      height: 1px;
      position: relative;
      top: 20px;
      width: calc(50% - 20px);
    }
  }

  .wrap {
    display: flex;
    flex-grow: 1;
    gap: 20px;
    overflow: hidden;

    > div {
      flex-grow: 1;
      width: 50%;
    }
  }

  @media (max-width: ${breakpointS}) {
    padding: 20px;

    h4 {
      display: none;
    }

    .wrap {
      flex-direction: column;

      > div {
        width: 100%;

        .map-controls,
        .property-map {
          display: none;
        }
      }

      .right {
        //height: 50vh;
        flex-grow: 0;
        order: 1;
      }

      .left {
        order: 2;
        padding-top: 0;
      }
    }
  }

  .left {
    padding-top: 20px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;

    > div {
      flex-basis: 50%;
    }

    > div.map-controls,
    > div.no-images {
      flex-basis: 100%;
    }

    > div.no-images {
      padding: 10px;
      text-align: center;
    }

    &.photo-sizer {
      @media (max-width: ${breakpointS}) {
        min-height: 50vh;
      }
    }
  }
`;

export const filterDetailPhotos = (
  photos: PropertyDetailPhoto[],
  section: PhotoCategoryType,
  detail?: string,
) => {
  let filteredPhotos: PropertyDetailPhoto[] = [];

  photos.forEach((photoObj) => {
    if (photoObj.tags.find((tag) => tag.includes(section))) {
      if (photoObj.tags.length === 1) {
        filteredPhotos.push(photoObj);
      } else {
        photoObj.tags = photoObj.tags.filter(
          (tag) => tag.includes(section) && tag !== section,
          // (tag) => tag.includes(section),
        );
        filteredPhotos.push(photoObj);
      }
    }
  });

  if (detail) {
    filteredPhotos = filteredPhotos.filter((photoObj) =>
      photoObj.tags.includes(section + "/" + detail),
    );
  }
  // console.log(
  //   filteredPhotos.sort((a, b) => {
  //     if (a.tags.length === 1 && a.tags[0] === section) {
  //       return -1;
  //     } else if (b.tags.length === 1 && b.tags[0] === section) {
  //       return 1;
  //     } else if (a.tags.length && b.tags.length) {
  //       return a.tags[0].localeCompare(b.tags[0]);
  //     } else {
  //       return -1;
  //     }
  //   }),
  // );

  return filteredPhotos.sort((a, b) => {
    if (a.tags.length === 1 && a.tags[0] === section) {
      return -1;
    } else if (b.tags.length === 1 && b.tags[0] === section) {
      return 1;
    } else if (a.tags.length && b.tags.length) {
      return a.tags[0].localeCompare(b.tags[0]);
    } else {
      return -1;
    }
  });
};

export const accordionPhotoState =
  (photos: PropertyDetailPhoto[], clickHandler: (index: number) => void) =>
  (detail: string) => {
    const filtered = photos.filter((photo) => photo.tags.includes(detail));

    if (filtered.length === 0) return null;

    const firstIndex = photos.findIndex((photo) => photo.tags.includes(detail));

    return {
      count: filtered.length,
      onClick: () => {
        clickHandler(firstIndex);
      },
    };
  };

export const OverviewDetail = ({
  property,
  photos,
  mapboxKey,
  kaiScanDate,
  listDate,
}: {
  property: PropertySnapshot;
  photos: PropertyDetailPhoto[];
  mapboxKey: string;
  kaiScanDate: string | null;
  listDate: string | null;
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  let daysOnMarket;

  if (listDate === null) {
    daysOnMarket = "--";
  } else if (parseInt(listDate) === 0) {
    daysOnMarket = 1;
  } else {
    daysOnMarket = listDate;
  }

  return (
    <DetailScreen className="container">
      <h4>Overview</h4>
      <div className="wrap">
        <div className="left">
          <PropertyAccordion
            title={"Details"}
            expandedDefault={true}
            rows={[
              { name: "Status", value: "Active" },
              // { name: "Exclusivity Days Remaining", value: "XX Days" },
              { name: "Days on Market", value: daysOnMarket + " Days" },
              // { name: "Est. Time to Sell on Zen", value: "XX Days" },
              {
                name: "Kai Walkthrough",
                value: kaiScanDate
                  ? new Date(kaiScanDate).toLocaleDateString("en-US")
                  : "--",
              },
              { name: "Zen Buyer Interest", value: "High" },
              { name: "Sold as", value: `${property.state.soldAs} sale` },
            ]}
          />
          <PropertyAccordion
            title="Summary"
            rows={[
              { name: null, value: property.state.propertyOverviewDescription },
            ]}
          />
        </div>
        <div className="right photo-sizer">
          <PhotoNavigator
            photos={photos}
            currentIndex={photoIndex}
            onPhotoChangeClick={setPhotoIndex}
            photoBackgroundSize={"contain"}
            paginationAlign={"right"}
            onSeeAllClick={() => {
              window.location.assign(
                getFusionDetailPath("property", property.propertyId, "photos"),
              );
            }}
          />

          <PropertyMap
            apiKey={mapboxKey}
            coords={property.state.coordinates || [0, 0]}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
