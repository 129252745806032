/**
 * The possible flood zones that a property can be in in the US. These values
 * are based on the FEMA flood zone designations.
 *
 * The NFHL (National Flood Hazard Layer) is represented by zones A and V.
 * Properties within these zones require flood insurance.
 *
 * Flood zones designations are somewhat confusion. For instance, Zone X has
 * both "shaded" and "unshaded" variants, where "shaded" is considered moderate
 * risk and "unshaded" is considered low risk.
 *
 * FEMA standards include a bunch of subtypes of A & V, but we don't really care
 * that much about those areas since those properties will generally be
 * disqualified. We simplify those to just "A" and "V", and drop the suffixes.
 *
 * @see https://msc.fema.gov/portal/search
 * @see
 * https://hazards-fema.maps.arcgis.com/apps/webappviewer/index.html?id=8b0adb51996444d4879338b5529aa9cd
 * @see
 * https://help.firststreet.org/hc/en-us/articles/360048256493-Understand-the-differences-between-FEMA-flood-zones
 * @see
 * https://www.fema.gov/sites/default/files/documents/fema_fim-appendix-h-flood-maps_apr2021.pdf
 */
export const floodZoneTypes = [
  "A", // 1-percent annual change areas
  "V", // 1-percent annual chance areas, coastal

  // b/c Cherre doesn't have suffixes unshaded/shaded
  "X", // then we combine unshaded/shaded chances for risk, <= 1 percent annual chance

  "X-shaded", // moderate risk, between 0.2 and 1 percent annual chance
  "B", // moderate risk, between 0.2 and 1 percent annual chance (deprecated)

  "X-unshaded", // low risk, less than 0.2 percent annual chance
  "C", // low risk, less than 0.2 percent annual chance (deprecated)

  "D", // undetermined risk (e.g. middle of Yellowstone National Park)
] as const;

export const floodZoneMap = {
  A: "High (1%+)",
  V: "High (1%+), coastal",
  "X-shaded": "Moderate (0.2 - 1%)",
  "X-unshaded": "Low (< 0.2%)",
  X: "Low to Moderate (< 1%)",
  D: "Undetermined",
  B: "Moderate (0.2 - 1%)",
  C: "Low (< 0.2%)",
};

export type FloodZoneType = (typeof floodZoneTypes)[number];
