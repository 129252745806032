import { useState } from "react";

import { PropertySnapshot, PropertyDetailPhoto } from "~/dataServices";

import { yearsFromYear } from "~/lib/presenters";

import {
  accordionPhotoState,
  DetailScreen,
  filterDetailPhotos,
  formatRenoArray,
  PropertyAccordion,
  PhotoNavigator,
} from "~/clients/zen/partials";

import { detailArrayAsString, detailIsIncluded } from "~/clients/zen/lib";
import { getFusionDetailPath } from "~/clients/fusion/fusionRoutes";

export const ConstructionDetail = ({
  property,
  photos,
  renoTotal,
}: {
  property: PropertySnapshot;
  photos: PropertyDetailPhoto[];
  renoTotal: number[];
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  const { state: propVals } = property;
  const filteredPhotos = filterDetailPhotos(photos, "construction");
  const getConstructionPhotoState = accordionPhotoState(
    filteredPhotos,
    setPhotoIndex,
  );

  return (
    <DetailScreen className="container">
      <h4>Construction</h4>
      <div className="wrap">
        <div className="left">
          <PropertyAccordion
            title="Size & Age"
            titleValue={`${property.sqft} Sq Ft • ${property.year}`}
            rows={[
              {
                name: "Square Feet",
                value: property.sqft?.toLocaleString() ?? "",
              },
              {
                name: "Vintage",
                value: property.year ?? "",
              },
            ]}
          />
          {detailIsIncluded("roof", propVals) && (
            <PropertyAccordion
              title="Roof"
              titleValue={`${yearsFromYear(property.roofYear ?? 0)} years old`}
              photoState={getConstructionPhotoState("construction/roof")}
              rows={[
                {
                  name: detailArrayAsString(propVals.roofMaterial),
                  value: yearsFromYear(property.roofYear ?? 0),
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(propVals.roofRenoEstimateRangeDollars),
                },
              ]}
            />
          )}
          {detailIsIncluded("parking", propVals) && (
            <PropertyAccordion
              title="Garage & Parking"
              titleValue={
                propVals.parkingSpots
                  ? `${propVals.parkingSpots} parking spots`
                  : ""
              }
              photoState={getConstructionPhotoState("construction/parking")}
              rows={[
                {
                  name: "Garage Type",
                  value: detailArrayAsString(propVals.parkingGarageType),
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.parkingRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("facade", propVals) && (
            <PropertyAccordion
              title="Facade"
              titleValue={detailArrayAsString(propVals.facadeType)}
              photoState={getConstructionPhotoState("construction/facade")}
              rows={[
                {
                  name: "Type",
                  value: detailArrayAsString(propVals.facadeType),
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.parkingRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("foundation", propVals) && (
            <PropertyAccordion
              title="Foundation"
              titleValue={detailArrayAsString(propVals.foundationType)}
              photoState={getConstructionPhotoState("construction/foundation")}
              rows={[
                {
                  name: "Type",
                  value: propVals.foundationType,
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.foundationRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("attic", propVals) && (
            <PropertyAccordion
              title="Attic"
              photoState={getConstructionPhotoState("construction/attic")}
              rows={[
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.atticRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("crawlspace", propVals) && (
            <PropertyAccordion
              title="Crawlspace"
              photoState={getConstructionPhotoState("construction/crawlspace")}
              rows={[
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.crawlspaceRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("fireplace", propVals) && (
            <PropertyAccordion
              title="Fireplace & Chimney"
              photoState={getConstructionPhotoState("construction/fireplace")}
              rows={[
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.fireplaceAndChimneyRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("solar", propVals) && (
            <PropertyAccordion
              title="Solar"
              photoState={getConstructionPhotoState("construction/solar")}
              rows={[
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.solarRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("addition", propVals) && (
            <PropertyAccordion
              title="Addition"
              titleValue={detailArrayAsString(propVals.additionType)}
              photoState={getConstructionPhotoState("construction/additions")}
              rows={[
                { name: "Type", value: propVals.additionType },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.additionRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          <PropertyAccordion
            title="Total Construction Reno"
            titleValue={formatRenoArray(renoTotal)}
            rows={
              [
                // { name: "Total Estimated Timeline", value: "xxx days" },
              ]
            }
          />
        </div>
        <div className={`right ${filteredPhotos.length > 0 && "photo-sizer"}`}>
          <PhotoNavigator
            photos={photos}
            currentIndex={photoIndex}
            onPhotoChangeClick={setPhotoIndex}
            photoBackgroundSize={"contain"}
            paginationAlign={"right"}
            filteredPhotos={filteredPhotos}
            category={"construction"}
            onSeeAllClick={() => {
              window.location.assign(
                getFusionDetailPath("property", property.propertyId, "photos"),
              );
            }}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
